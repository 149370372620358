<template>
  <div :class="$style.categories">
    <div :class="$style.wrapper">
      <el-button size="small" type="primary" @click="submitForm('form')">
        Редактировать
      </el-button>
      <el-button size="small" type="primary" @click="SaveAndComeBack('form')">
        Редактировать и вернуться
      </el-button>
    </div>
    <el-form
      :model="form"
      :rules="rules"
      ref="form"
      label-position="right"
      label-width="12.5rem"
    >
      <el-form-item label="Название" prop="name">
        <el-input
          @focus="!form.slug ? handleNameInput(form.name) : ''"
          v-model="form.name"
        ></el-input>
      </el-form-item>
      <el-form-item label="Имя в url" prop="slug">
        <el-input v-model="form.slug" clearable></el-input>
      </el-form-item>
      <el-form-item label="Активный">
        <el-checkbox v-model="form.isActive"></el-checkbox>
      </el-form-item>
      <el-form-item label="Внешний код Мой склад" prop="moySkladExternalCode">
        <el-input v-model="form.moySkladExternalCode"></el-input>
      </el-form-item>
      <el-form-item label="SEO-тексты">
        <SeoTextsBlock :seoTexts="form.seoTextsIds" @getSeo="getSeoTexts" />
      </el-form-item>

      <el-form-item label="SEO-FAQ">
        <SeoFaqsBlock :seoFAQs="form.seoFaqsIds" @getSeo="getSeoFaqs" />
      </el-form-item>

      <SeoBlock
        :title.sync="form.seo.title"
        :description.sync="form.seo.description"
        :og-title.sync="form.seo.ogTitle"
        :og-description.sync="form.seo.ogDescription"
        :og-image.sync="form.seo.ogImage"
        :class="$style.seo"
      />
    </el-form>
  </div>
</template>

<script>
import delivery from '@/delivery'
import regexp from '@/helpers/regexp.js'
import notifications from '@/mixins/notifications'

import { transliterate } from '@/helpers/slug'
import { ADDWINE_ADMIN_ROUTES } from '@/constants/routing'

import SeoBlock from '@/components/moleculs/SEO.vue'
import SeoTextsBlock from '@/components/organisms/SeoTextsBlock.vue'
import SeoFaqsBlock from '@/components/organisms/SeoFaqsBlock.vue'

export default {
  components: { SeoBlock, SeoTextsBlock, SeoFaqsBlock },
  mixins: [notifications],
  ADDWINE_ADMIN_ROUTES,
  data() {
    return {
      treeData: [],
      form: {
        name: '',
        moySkladExternalCode: '',
        slug: '',
        isActive: false,
        parentPath: [],
        parentId: '',
        seoTextsIds: [],
        seoFaqsIds: [],
        seo: {
          title: '',
          description: '',
          ogTitle: '',
          ogDescription: '',
          ogImage: '',
        },
      },
      rules: {
        name: [
          {
            required: true,
            message: 'Пожалуйста, заполните поле',
            trigger: 'blur',
          },
        ],
        moySkladExternalCode: [
          {
            trigger: 'blur',
          },
        ],
        slug: [
          {
            required: true,
            message: 'Пожалуйста, заполните поле',
            trigger: 'blur',
          },
          {
            pattern: regexp.slug,
            message: 'Введен недопустимый символ',
            trigger: 'change',
          },
        ],
        seo: {
          title: [
            {
              required: true,
              message: 'Пожалуйста, заполните поле',
              trigger: 'blur',
            },
          ],
          description: [
            {
              required: true,
              message: 'Пожалуйста, заполните поле',
              trigger: 'blur',
            },
          ],
          ogTitle: [
            {
              required: true,
              message: 'Пожалуйста, заполните поле',
              trigger: 'blur',
            },
          ],
          ogDescription: [
            {
              required: true,
              message: 'Пожалуйста, заполните поле',
              trigger: 'blur',
            },
          ],
          ogImage: [
            {
              required: true,
              message: 'Пожалуйста, заполните поле',
              trigger: 'change',
            },
          ],
        },
      },
    }
  },
  created() {
    this.getCategory()
  },
  methods: {
    handleNameInput(value) {
      this.form.slug = transliterate(value)
    },
    getSeoTexts(seoTexts) {
      this.form.seoTextsIds = seoTexts
    },
    getSeoFaqs(seoFaqs) {
      this.form.seoFaqsIds = seoFaqs
    },
    async getCategory() {
      const loading = this.$loading({
        lock: true,
      })

      const { value, error } =
        await delivery.ProductsCore.CategoriesActions.getById(
          this.$route.params.id,
        )
      if (error) return

      this.form = value

      loading.close()
    },

    resetForm() {
      this.form.name = ''
      this.form.moySkladExternalCode = ''
      this.form.slug = ''
      this.form.parentPath = []
      this.form.isActive = false
      this.seo = {}
    },

    async submitForm(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          const loading = this.$loading({
            lock: true,
          })

          const { error } =
            await delivery.ProductsCore.CategoriesActions.update(
              this.$route.params.id,
              this.form,
            )
          loading.close()

          if (error) {
            this.showNotification('Ошибка обновления категории', 'error')
            return
          }

          this.showNotification('Категория успешно обновлена', 'success')

        }
      })
    },
    async SaveAndComeBack(form){
      await this.submitForm(form)
      this.$router.go(-1)
    },
  },
}
</script>

<style lang="scss" module>
@import '@/assets/styles/colors.scss';
.categories {
  padding: 0 1rem;
  .wrapper {
    @include stickyWrapper;
  }
  .seo {
    & > div:first-child {
      margin: 2rem 0 1.5rem 2.5rem;
    }
  }
}
</style>
